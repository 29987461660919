// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################,

const StyledCardsBlockWrapper = styled.div`
	gap: var(--size-grid);
	flex-direction: column;
	display: flex;
	flex: 1;

	${({ $fullHeight }) =>
		$fullHeight &&
		css`
			height: 100%;
		`}
`;

// #####################################################

export const CardsBlockWrapper = ({ children, ...props }) => {
	return (
		<StyledCardsBlockWrapper {...props}>{children}</StyledCardsBlockWrapper>
	);
};

// #####################################################
