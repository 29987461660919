// ** React Imports
import { useContext, useState, useEffect, forwardRef, useMemo } from "react";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const DEFAULT_GAP = "3px";

const columnsDefault = {
	phone: 3,
	tablet: 3,
	desktop: 3,
	giant: 3,
};

// #####################################################

function MasonryComponent(
	{
		columns: columnsByProps,
		gap = DEFAULT_GAP,
		children,
		style,
		customMaxWidth,
		elementRef,
		...otherProps
	},
	forwardedRef
) {
	const { isPhone, isTablet, isDesktop, isGiant } =
		useContext(deviceTypeContext);

	const columns = columnsByProps ? columnsByProps : columnsDefault;

	// ** Ustawienie stanu początkowego - ilość kolumn w zależności od urządzenia użytkownika
	const [currentColumns, setCurrentColumns] = useState(() => {
		if (isPhone) {
			return columns.phone;
		} else if (isTablet) {
			return columns.tablet;
		} else if (isDesktop && !isGiant) {
			return columns.desktop;
		} else {
			return columns.giant;
		}
	});

	const finalArray = useMemo(() => {
		const divideChildrenIntoArrays = (children, numColumns) => {
			// Tworzymy tablicę wysokości dla każdego dziecka
			const heights = children.map((child) => {
				// * Tworzenie tablicy wysokości dla każdego dziecka
				const maxAspectRatio = 4 / 3; // Maksymalny współczynnik proporcji (szerokość / wysokość)

				let height = child?.props?.height; // Wysokość dziecka
				let width = child?.props?.width; // Szerokość dziecka

				// Sprawdzamy, czy proporcje dziecka przekraczają maksymalny współczynnik proporcji
				if (
					width / height > maxAspectRatio ||
					height / width > maxAspectRatio
				) {
					if (width > height) {
						// Jeśli szerokość jest większa od wysokości, zmieniamy szerokość na zaokrągloną wartość proporcji
						width = Math.ceil(maxAspectRatio * height);
					} else {
						// W przeciwnym razie zmieniamy wysokość na zaokrągloną wartość proporcji
						height = Math.ceil(maxAspectRatio * width);
					}
				}

				// Obliczamy wysokość na podstawie proporcji szerokości i wysokości oraz wartości 210
				return (height / width) * 210;
			});

			// Tworzymy tablicę podtablic, gdzie każda podtablica będzie reprezentować jedną kolumnę
			const subsets = Array.from({ length: numColumns }, () => []);
			// Tworzymy tablicę sum, gdzie każdy element będzie przechowywał sumę wysokości dla danej kolumny
			const sums = Array.from({ length: numColumns }, () => 0);

			// Dla każdej rozwiązanej wysokości
			heights.forEach((height, index) => {
				// Inicjalizujemy zmienną columnIndex na 0, która będzie przechowywać indeks kolumny o najmniejszej sumie wysokości
				let columnIndex = 0;
				// Inicjalizujemy zmienną minSum na wartość sums[0], która będzie przechowywać najmniejszą sumę wysokości
				let minSum = sums[0];

				// Przechodzimy przez każdą kolumnę, aby znaleźć tę o najmniejszej sumie wysokości
				for (let i = 1; i < numColumns; i++) {
					// Sprawdzamy, czy suma wysokości dla bieżącej kolumny jest mniejsza od minSum
					if (sums[i] < minSum) {
						// Jeśli tak, aktualizujemy minSum na tę wartość
						minSum = sums[i];
						// Aktualizujemy columnIndex na indeks bieżącej kolumny
						columnIndex = i;
					}
				}

				// Dodajemy wysokość do sumy dla odpowiedniej kolumny
				sums[columnIndex] += height;
				// Dodajemy dziecko do odpowiedniej podtablicy (kolumny)
				const newChildren = {
					...children[index],
					correctHeight: height,
				};
				subsets[columnIndex].push(newChildren);
			});

			return subsets;
		};

		if (children && children.length > 0) {
			return divideChildrenIntoArrays(children, currentColumns);
		}
	}, [children, currentColumns]);

	useEffect(() => {
		if (isPhone) {
			setCurrentColumns(columns.phone);
		} else if (isTablet) {
			setCurrentColumns(columns.tablet);
		} else if (isDesktop && !isGiant) {
			setCurrentColumns(columns.desktop);
		} else if (isDesktop && isGiant) {
			setCurrentColumns(columns.giant);
		}
	}, [isPhone, isTablet, isDesktop, isGiant]);

	// #####################################################

	return (
		<div
			ref={forwardedRef}
			style={{
				display: "flex",
				width: "100%",
				maxWidth: customMaxWidth || "100%",
				...style,
			}}
			{...otherProps}
		>
			{finalArray?.map((column, columnIndex) => {
				let shortestColumnIndex = null;
				let minColumnHeight = Number.POSITIVE_INFINITY;

				for (let i = 0; i < finalArray.length; i++) {
					let columnHeight = 0;

					for (let child of finalArray[i]) {
						columnHeight += child.correctHeight;
					}

					if (columnHeight < minColumnHeight) {
						minColumnHeight = columnHeight;
						shortestColumnIndex = i;
					}
				}

				const shortestColumn = shortestColumnIndex === columnIndex;
				return (
					<div
						key={columnIndex}
						style={{
							marginLeft: columnIndex && gap,
							width: "100%",
							maxWidth: "100%",
							minWidth: customMaxWidth ? 0 : "unset",
						}}
					>
						{column.map((item, itemIndex) => {
							const triggerIndex = 4;
							const isTrigger =
								itemIndex === column.length - triggerIndex;

							return (
								<div
									key={itemIndex}
									style={{
										paddingTop: itemIndex && gap,
										width: "100%",
										maxWidth: "100%",
									}}
									ref={
										isTrigger &&
										shortestColumn &&
										elementRef
											? elementRef
											: null
									}
								>
									{item}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}

// #####################################################

const Masonry = forwardRef(MasonryComponent);

export default Masonry;
